<template>
  <div class="terms">
    <header>
      <router-link to="/" tag="div">
        <div class="maxWidth top_log">
          <div class="left_logo">
            <img src="@/assets/image/top-logo.png" alt="" />
          </div>
        </div>
      </router-link>
    </header>
    <div class="hed_tit">
      <div class="centerWidth">
        <h1>Privacy Agreement</h1>
      </div>
    </div>
    <div class="terms_list">
      <div class="centerWidth">
        <ul>
          <li>
            <p>
              We want you to know what information we collect, what information
              we don't collect, and how we collect, use and store it. We have
              designed our systems so that we do not have sensitive data about
              our customers; we cannot provide data that we do not have, even if
              we are forced to do so. This Privacy Policy will help you
              understand how Hyperlink collects, uses and stores information.
            </p>
          </li>
          <li>
            <h2>1. General information</h2>
            <p>Hyperlink collects three types of information.</p>
            <p>
              1. personal information related to your account. This information
              is collected in order to manage your Hyperlink subscription.
            </p>
            <p>
              2. aggregate application statistics. hyperlink collects minimal
              information about usage to maintain excellent customer support and
              service quality.
            </p>
            <p>
              3. Anonymous application diagnostics including crash reports. This
              feature is similar to the "Send Bug Report" option.
            </p>
          </li>

          <li>
            <h2>2.Personal Information</h2>
            <p>
              Hyperlink collects personal information that you provide to us
              directly through registration. We ask you to provide personal
              information, such as an email address, in order to set up a
              Hyperlink account so that we can send you emails and respond to
              support queries you send us.
            </p>
            <p>Hyperlink uses your email address for the following reasons.</p>
            <ul class="terms_ul">
              <li>
                To provide links to our website, including password reset
                emails.
              </li>
              <li>To send you updates and announcements.</li>
              <li>
                To send marketing messages and you may opt out of receiving
                marketing emails on a per-option basis.
              </li>
              <li>
                To send marketing information, you can choose not to receive
                marketing emails.
              </li>
            </ul>
            <p>
              Hyperlink will only use your personal information for the purposes
              outlined in this privacy policy and we will not sell your personal
              information to third parties.
            </p>
          </li>

          <li>
            <h2>3. Applications and Application Versions</h2>
            <p>
              We collect information relating to your activated applications and
              application versions. Knowing your current application version
              allows our support team to work with you to resolve technical
              issues.
            </p>
            <!-- <ul class="terms_ul">
              <li>
                We do not know which user has ever visited a particular website
                or service.
              </li>
              <li>
                We don't know which user connected to the VPN at a specific time
                or which VPN server IP address they used.
              </li>
              <li>
                We do not know the original IP address set of the user's
                computer.
              </li>
            </ul>
            <p>
              If someone tries to force HyperlinkVPN to release user information
              based on any of the above, we will not be able to provide this
              information because the data does not exist.
            </p>
            <p>
              In order to maintain excellent customer support and service
              quality, HyperlinkVPN collects the following information related
              to your VPN usage.
            </p> -->
          </li>

          <li>
            <h2>
              4. Anonymous application diagnostics, including crash reports
              (which can be turned off by the user)
            </h2>
            <p>
              With your permission, we collect anonymous application diagnostic
              data, which includes crash reports, usability diagnostics. The
              information we receive is completely anonymous. If you choose to
              share this information with Hyperlink, we will collect anonymous
              information about
            </p>
            <p>
              Application diagnostics, which includes crash reports and
              usability diagnostics. These are processed by these third parties
              on an anonymous basis.
            </p>
          </li>

          <li>
            <h2>5.Jurisdiction and Applicable Law</h2>
            <p>
              Hyperlink's core mission is to protect the privacy of your
              information and Hyperlink is headquartered and registered to do
              business in Singapore.
            </p>
          </li>
          <li>
            <h2>6. Storage of information relating to emails, feeds</h2>
            <p>
              Hyperlink will record any complaints, questions, suggestions, etc.
              that you submit to us through our services. Depending on how you
              contact Hyperlink, we may collect your email address and any other
              information you provide to us. Having a complete record of
              correspondence allows our staff to provide the best possible
              customer support experience.
            </p>
          </li>

          <li>
            <h2>7. Security measures to protect your information</h2>
            <p>
              Hyperlink uses security measures, such as advanced procedures and
              technologies, to prevent the loss, misuse, unauthorized access,
              disclosure or modification of any information. However, we need to
              be aware that no data security measure in the world can provide
              100% protection, but we will do our best to ensure the security of
              user privacy.
            </p>
          </li>
          <li>
            <h2>8. Cookie</h2>
            <p>
              A cookie is a small text file that stores information about your
              visit to our site. cookies help us to provide certain features,
              such as site registration and language settings, that allow
              Hyperlink to optimise and improve the user experience on our site.
              As we continue to update and improve our website, the cookies we
              use may change over time. Hyperlink is 100% protected, but we do
              our best to keep user privacy as secure as possible.
            </p>
          </li>
          <li>
            <h2>9.Consent and Age Restrictions</h2>
            <p>
              By using this application, software or service, you consent to the
              processing of your information as described in our Terms of
              Service and Privacy Policy. We are 100% committed to protecting
              your privacy, but we will do our utmost to ensure that your
              privacy is protected.
            </p>
            <p>
              The Services are available to adults aged 18 and over. If you
              believe that your child has provided us with information, please
              notify us immediately.
            </p>
          </li>
          <li>
            <h2>10.Changes to our Privacy Policy</h2>
            <p>
              Subject to applicable privacy laws and principles, we may change
              our privacy policy from time to time without notice to you. Your
              continued use of the Site or the Services constitutes your
              acceptance of our Privacy Policy.
            </p>
          </li>
          <li>
            <h2>11.How to contact Hyperlink</h2>
            <p>
              If you have any questions about our privacy policy and how we
              handle your information, please feel free to call Hyperlink's
              official email address.
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
    
    <script>
export default {};
</script>
    
<style lang="less" scoped>
@import url("@/assets/common/css/clauseComm.less");
</style>
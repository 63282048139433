import { login, logout, search } from './userlogin'
import { typeall, listshow } from './serveApi'
export default {
    // 登录
    login,
    logout,
    search,
    typeall,
    listshow

}
<template>
  <div class="terms">
    <header>
      <router-link to="/" tag="div">
        <div class="maxWidth top_log">
          <div class="left_logo">
            <img src="@/assets/image/top-logo.png" alt="" />
          </div>
        </div>
      </router-link>
    </header>
    <div class="hed_tit">
      <div class="centerWidth">
        <h1>Terms of Service</h1>
      </div>
    </div>
    <div class="terms_list">
      <div class="centerWidth">
        <ul>
          <li>
            <p>
              This Terms of Service document outlines the terms and conditions
              of use of the services offered by Hyperlink. These terms also
              govern the use of and access to Hyperlink content.
            </p>
            <p>
              By agreeing to these terms, you also agree to the Privacy Policy.
            </p>
          </li>
          <li>
            <h2>1. Acceptance of Acceptance</h2>
            <p>
              By accessing the Content or Services, you agree, on behalf of
              yourself or a virtual entity, to fully comply with and be legally
              bound by these Terms. These Terms constitute a legally binding
              agreement between you and Hyperlink. If you do not agree to any
              part of these Terms, you may not use our Services.
            </p>
            <p>
              By creating an account to use our Services, you represent that you
              are at least eighteen (18) years old or that you are a valid legal
              entity and that the registration information you provide is
              accurate and complete.
            </p>
          </li>
          <li>
            <h2>2.Privacy Policy</h2>
            <p>
              Hyperlink is committed to protecting your privacy. During your
              registration, we may collect sensitive personal information, such
              as your email address. We only collect information that is
              necessary for the delivery of our website and services.
            </p>
          </li>
          <li>
            <h2>3. Changes to the Terms of Service</h2>
            <p>
              Hyperlink may update these terms from time to time without notice.
              If you continue to use Hyperlink's services, content, sites,
              applications or software after these changes are effective, you
              agree to the revised terms and accept our Privacy Policy.
            </p>
          </li>
          <li>
            <h2>4.Language and content translation</h2>
            <p>
              All of our content was originally written in English. Any
              translations of our content are done on a best-effort basis. We
              cannot guarantee the accuracy of the translated content. If there
              is any discrepancy between the translated content and the English
              content, the English content shall prevail.
            </p>
          </li>

          <li>
            <h2>5. Acceptable Use Policy</h2>
            <p>
              The Hyperlink Services may be accessed from multiple countries, so
              it is your responsibility to evaluate whether your use of the
              Site, Application, software or Services complies with local laws
              and regulations. Whenever you use the Site, Application, Software
              or Services, you are expected to comply with these Terms and
              applicable laws, regulations and policies.
            </p>
            <p>
              It is your responsibility to maintain the confidentiality of your
              Hyperlink account information. You are responsible for all
              activity under your account. If you discover or suspect that
              someone has accessed your account without your authorization, you
              are advised to notify us immediately so that we can revoke your
              account credentials and issue new credentials.
            </p>
            <p>
              Hyperlink aims to provide the best possible service to all our
              users. In this sense, we ask that you do not misuse our content or
              services. Misuse means any use, access or interference with the
              Content or Services that violates the Terms or applicable laws and
              regulations.
            </p>
            <p>
              Hyperlink reserves the right to take appropriate action to protect
              the Services from misuse or use to harm others. If you misuse the
              Services, Hyperlink may terminate your account without
              explanation.
            </p>
          </li>
          <li>
            <h2>6. Limitation of Liability</h2>
            <p>
              Hyperlink shall not be liable in any way to any subscriber or
              other individual for any loss or damage suffered by you. in no
              event shall hyperlink, its members, officers or employees be
              liable for any direct, special, indirect, consequential or
              incidental damages or any other type of loss or damage, even if
              they have been advised of the possibility thereof. The foregoing
              shall not apply to the extent prohibited by applicable law.
            </p>
          </li>

          <li>
            <h2>7. Applicable Law</h2>
            <p>
              This Agreement shall be governed by and construed in accordance
              with the laws of Singapore, excluding its conflict of laws rules.
            </p>
          </li>
          <li>
            <h2>8. Final Terms</h2>
            <p>
              If any provision of this Agreement is held to be invalid or
              unenforceable, that provision shall be construed in a manner
              consistent with applicable law to reflect the original intent of
              that provision, and the remaining provisions of this Agreement
              shall remain in full force and effect. Failure to exercise or
              enforce any right or provision of this Agreement shall not
              constitute a waiver of such right or provision.
            </p>
          </li>
          <li>
            <h2>9. Contact Us</h2>
            <p>
              If you have any questions about our privacy policy and how we
              handle your information, please feel free to contact us at
              Hyperlink's official email address.
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
    
    <script>
export default {};
</script>
    
<style lang="less" scoped>
@import url("@/assets/common/css/clauseComm.less");
</style>